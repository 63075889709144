import React from "react";
import Layout from "../../components/Layout";
import { FaAngleRight } from "@react-icons/all-files/fa/FaAngleRight";

import { Link, graphql } from "gatsby";
import Video from "../../components/Video";
import Accordion from "../../components/Accordion";

import HeroComponent from "../../components/hero/HeroComponent";
import ItemSlider, {
  ItemSliderGrid,
  SimpleGrid,
} from "../../components/ItemSlider/ItemSlider";

const HeaderButtonObject = [
  { title: "B2B", link: "/service/business-to-business" },
  { title: "B2C", link: "/service/business-to-consumer" },
];

const Services = ({ data }) => {
  const daa = data.strapiPages;
  const servicesList = data.allStrapiServices.nodes;
  const ProductsList = data.allStrapiProducts.nodes;
  return (
    <Layout title={data.strapiPages.title}>
      <div className="services">
        <HeroComponent
          image={daa.image.localFile.childImageSharp.fluid}
          title={""}
          buttonObject={HeaderButtonObject}
          highlighter={true}
        />
        <div className="ssbody wrapper_max">
          <div className="naviBody">
            <div className="navigation">
              <h3>Our Services</h3>
              <div className="list">
                {servicesList.map((v, k) => (
                  <Link
                    key={k}
                    activeClassName="active"
                    to={"/services/" + v.slug}
                  >
                    <div key={k}>
                      <p>{v.title}</p>
                      <FaAngleRight />
                    </div>
                  </Link>
                ))}
              </div>
            </div>

            <div className="navigation">
              <h3>Our Products</h3>
              <div className="list list2">
                {ProductsList.map((v, k) => (
                  <Link activeClassName="active" to={"/products/" + v.slug}>
                    <div key={k}>
                      <p>{v.title}</p>
                      <FaAngleRight />
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>

          <div className="content">
            <div
              className="page-body"
              dangerouslySetInnerHTML={{ __html: daa.body }}
            />
            <h3>Complete Range of Services</h3>
            {/* <ItemSlider total={data.allStrapiServices.totalCount} loop={data.allStrapiServices.nodes} sub="services"/> */}

            <ItemSliderGrid
              loop={data.allStrapiServices.nodes}
              sub="services"
            />

            {/* <h3>2. USP from PESTGOGO</h3>
            <ItemSlider total={data.allStrapiServices.totalCount} loop={data.allStrapiServices.nodes} sub="services"/> */}

            <h3>PESTGOGO Industry Expertise</h3>
            <SimpleGrid
              loop={data.allStrapiIndustryExpertises.nodes}
              sub="industry-expertises"
            />

            <Accordion alphaBullet={false} data={daa.section} />
          </div>
        </div>
      </div>

      {daa.youtubeLink != null &&
        daa.youtubeLink != "null" &&
        daa.youtubeLink != "" && <Video url={daa.youtubeLink} />}
    </Layout>
  );
};

export const query = graphql`
  {
    strapiPages(slug: { eq: "services" }) {
      id
      slug
      title
      body
      titleHindi
      section {
        body
        title
      }
      youtubeLink
      image {
        localFile {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1900) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }

    allStrapiServices {
      nodes {
        title
        titleHindi
        slug
        description
        icon {
          localFile {
            publicURL
          }
        }
      }
      totalCount
    }

    allStrapiIndustryExpertises {
      nodes {
        slug
        title
      }
    }

    allStrapiProducts {
      nodes {
        slug
        title
      }
    }
  }
`;

export default Services;
